import React from 'react';

import {
  Stack,
  StackProps,
  Card,
  CardProps,
  CardHeader,
  CardHeaderProps,
  CardContent,
  CardContentProps,
} from '@mui/material';

import { useResponsive } from '../../../hooks';

export interface CardStackProps {
  /**
   * CardHeader のタイトルに設定される。CardHeaderProps.title よりこちらが優先される
   */
  title?: string;
  StackProps?: StackProps;
  CardProps?: CardProps;
  /**
   * CardHeaderのprops。CardHeaderProps.title より titleのほうが優先される。
   */
  CardHeaderProps?: CardHeaderProps;
  CardContentProps?: CardContentProps;
  children: React.ReactNode;
}

export default function CardStack({
  title,
  StackProps,
  CardProps,
  CardHeaderProps,
  CardContentProps,
  children,
}: CardStackProps) {
  const isMobile = useResponsive('down', 'sm');

  return isMobile ? (
    <Stack {...CardProps}>
      {(title || CardHeaderProps) && <CardHeader {...CardHeaderProps} {...(title && { title })} />}
      <Stack {...StackProps}>{children}</Stack>
    </Stack>
  ) : (
    <Card {...CardProps}>
      {(title || CardHeaderProps) && <CardHeader {...CardHeaderProps} {...(title && { title })} />}
      <CardContent {...CardContentProps}>
        <Stack {...StackProps}>{children}</Stack>
      </CardContent>
    </Card>
  );
}
