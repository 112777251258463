import { useEffect, useState } from 'react';

import { m } from 'framer-motion';

import { Container, Typography } from '@mui/material';

import { ForbiddenIllustration } from '../../assets';
import { useAccess } from '../../contexts';
import { MotionContainer, varBounce } from '../elements';

// ----------------------------------------------------------------------

export const hasPermission = (
  permission: string | undefined,
  userPermissions: string[]
): boolean => {
  // console.log(permission);
  if (!permission) {
    // console.log('Nothing Restrictions');
    return true;
  }

  if (userPermissions.includes('all:all')) {
    // This user is the God Almighty on this app :D
    return true;
  }

  /**
   * TODO: permission にワイルドカードを導入検討中
   * 現状、 read:products のように {action}:{model} という一般的なパーミッションの形式になっている。
   * それを read:products:maker のように {action}:{model}:{scope} という形式に変更した場合に、
   * reac:products:* で read:products:all や read:products:maker などを許容できるようにすることを検討
   * しかし、scope の判定は別で行って、この形式を導入するか未定。
   */
  const result = permission.includes('*')
    ? (userPermissions.some(() => userPermissions.indexOf(permission) !== -1) as boolean)
    : (userPermissions.some((userPermission) => {
        const wildCardRemoved = permission.replace(':*', '');
        return userPermission.includes(wildCardRemoved);
      }) as boolean);

  if (process.env.NODE_ENV === 'development') {
    // for Dev on local
    return true;
  }

  return result;
};

// ----------------------------------------------------------------------

export interface PermitGuardProps {
  hasContent?: boolean;
  permission?: string;
  children: React.ReactNode;
}

export default function PermitGuard({ hasContent, permission, children }: PermitGuardProps) {
  const { userPermissions } = useAccess();

  const [allowed, setAllowed] = useState(false);

  const handleAccess = (userPermissions: string[]) => {
    setAllowed(hasPermission(permission, userPermissions));
  };

  useEffect(() => {
    handleAccess(userPermissions);
  }, [userPermissions]);

  if (permission && !allowed) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            アクセス権限がありません
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
