import { GridColDef, GridEnrichedColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';

import { fDateTime } from './formatTime';
import { FormFieldSettingList, OptionSetting } from '../types';

export const getColsFromFieldSettings = (
  fields: FormFieldSettingList,
  pickKeys?: string[],
  defaultWidth = 100
) => {
  const results: Record<string, GridColDef> = {};

  Object.entries(fields).forEach(([key, field]) => {
    results[key] = {
      field: key,
      headerName: field.label,
      type: field.dataType,
      width: defaultWidth,
      ...(pickKeys && !pickKeys.includes(key) && { hide: true }),
    };
  });

  return results;
};

export const removeColsField = (
  keys: string[] = [],
  cols: Record<string, GridColDef>
): Record<string, GridColDef> => {
  const results: Record<string, GridColDef> = cols;
  if (!keys.length) {
    return results;
  }
  keys.forEach((key, index) => {
    results[key] = { ...results[key], hide: true };
  });
  return results;
};

/**
 * 通常表示するカラム以外をあとで上書きする関数です
 * @param {{ index?: number, col: GridColDef }[]} colIndexes 置き換えるカラムの配列ですindexを指定することで場所を指定できます
 * @param {Record<string, GridColDef>} cols ベースとなるカラムです。
 * @param {string[]} removeKeys 表示しないカラムのキーです。配列で複数設定できます
 */
export const colsFormatter = (
  colIndexes: { index?: number; col: GridColDef }[],
  cols: Record<string, GridColDef>,
  removeKeys: string[] = []
): Record<string, GridColDef> => {
  const _cols: Record<string, GridColDef> = cols;
  colIndexes.forEach((v) => {
    if (_cols[v.col.field]) {
      if (v.index) {
        delete _cols[v.col.field];
      } else {
        _cols[v.col.field] = v.col;
      }
    } else {
      if (!v.index) {
        _cols[v.col.field] = v.col;
      }
    }
  });

  const resultArr: GridColDef[] = [];
  const results: Record<string, GridColDef> = {};
  Object.entries(_cols).forEach(([key, value], i) => {
    const colIndex = colIndexes.find((v) => v.index === i);
    if (colIndex) {
      resultArr.splice(i, 0, colIndex.col);
    }
    resultArr.push(value);
  });

  resultArr.forEach((v) => {
    results[v.field] = v;
  });

  if (removeKeys.length) {
    removeKeys.forEach((key, index) => {
      delete results[key];
    });
  }

  return results;
};

export const optionColBase = <T extends Record<string, any>>(
  commonCol: GridColDef<T, any, any>,
  options?: Record<string, OptionSetting>
): GridEnrichedColDef<T> => {
  const formatCol: GridEnrichedColDef<T> = {
    ...commonCol,
    headerAlign: 'left',
    align: 'left',
    valueGetter(params: GridValueGetterParams) {
      if (!options) {
        return '';
      }
      const data = params.row as T;
      const obj = data as Record<string, any>;
      const labels: string[] = [];
      const value = obj[commonCol.field];
      if (value) {
        if (typeof value === 'boolean') {
          if (value) {
            return options['true'].label;
          } else {
            return options['false'].label;
          }
        }

        if (typeof value === 'string' || typeof value === 'number') {
          return options[value] ? options[value].label : '';
        }
        if (value.length) {
          value.forEach((v: any) => {
            const s = options[v].label;
            labels.push(s);
          });
        }
      }
      return labels.length ? labels.join('、') : '';
    },
  };
  return formatCol;
};

const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());

export const dateColBase = <T extends Record<string, any>>(
  commonCol: GridColDef<T, any, any>
): GridEnrichedColDef<T> => {
  const formatCol: GridEnrichedColDef<T> = {
    ...commonCol,
    width: 200,
    valueGetter(params: GridValueGetterParams) {
      const data = params.row as T;
      const obj = data as Record<string, any>;
      const value = obj[commonCol.field];
      if (value) {
        if (isInvalidDate(new Date(value))) {
          return value;
        } else {
          return fDateTime(value);
        }
      } else {
        return '';
      }
    },
  };
  return formatCol;
};

/** csvで書き出す際にbooleanは「真」,「偽」の値で抽出されるためformat */
export const booleanColBase = <T extends Record<string, any>>(
  commonCol: GridColDef<T, any, any>,
  options?: Record<string, OptionSetting>
): GridEnrichedColDef<T> => {
  const formatCol: GridEnrichedColDef<T> = {
    ...commonCol,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    valueFormatter({ value }) {
      /** csvの表記やGridでの表示 */
      return value;
    },
    valueGetter({ value }) {
      /** フィルターなど検索の方で使用される値 */
      const val = String(value);
      /** options があるフィールドでも値の形式が true false なら、○xで表示 */
      if (val === 'true' || val === 'false') {
        return val === 'true' ? '○' : '×';
      } else {
        return options?.[val] ? options?.[val].label : '';
      }
    },
  };
  return formatCol;
};
