import { useMemo, ReactNode } from 'react';

import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  PaletteOptions,
} from '@mui/material/styles';

import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import paletteBase from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  paletteDynamic?: { light: PaletteOptions; dark: PaletteOptions };
  shadowsDynamic?: { light: string[]; dark: string[] };
};

export default function ThemeProvider({ children, paletteDynamic }: Props) {
  const { themeMode, themeDirection } = useSettings();

  const isLight = themeMode === 'light';

  const palette = paletteDynamic || paletteBase;
  const _customShadows = customShadows(palette);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? _customShadows.light : _customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  );
}
