import Router from 'next/router';
import React, { ReactNode } from 'react';

import { AppState, Auth0Provider } from '@auth0/auth0-react';

type Auth0API = {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
};

type Props = {
  children: ReactNode;
  auth0API: Auth0API;
};

const onRedirectCallback = (appState?: AppState): void => {
  Router.replace(appState?.returnTo || window.location.origin);
};

const AuthProvider = ({ children, auth0API }: Props) => {
  const { domain, clientId, redirectUri, audience } = auth0API;

  const config = {
    domain,
    clientId,
    redirectUri,
    audience,
    onRedirectCallback,
  };

  return <Auth0Provider {...config}>{children}</Auth0Provider>;
};

export default AuthProvider;
