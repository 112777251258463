import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import ThemeProvider from '../../theme';

export interface ConfirmProps {
  body: string;
  title?: string;
  okLabel?: string;
  cancelLabel?: string;
}

export interface ConfirmDialogProps extends ConfirmProps {
  show: boolean;
  proceed: (val: boolean) => void;
}

/**
 * Must call from '@hui/utils/confirmDialog'
 * @param ConfirmDialogProps
 * @returns
 */
export default function ConfirmDialog({
  show,
  proceed,
  body,
  title,
  okLabel = '確定',
  cancelLabel = 'キャンセル',
}: ConfirmDialogProps) {
  return (
    // Must warp ThemeProvider because '@hui/utils/confirmDialog' insert this component to out of <main>
    <ThemeProvider>
      <Dialog open={show} onClose={() => proceed(false)}>
        {title && <DialogTitle>{title}</DialogTitle>}

        <DialogContent dangerouslySetInnerHTML={{ __html: body }} />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={() => proceed(false)}>
            {cancelLabel}
          </Button>
          <Button variant="contained" onClick={() => proceed(true)}>
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
