import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

// utils
//
import useAxios from 'axios-hooks';

import { FormedResponseBody } from '@hui/libs/next';
import { dataReady } from '@hui/utils';
import localStorageAvailable from '@hui/utils/localStorageAvailable';

import { GetGovernmentsListReqBody, GovernmentsList } from '@/models/Government';
import { useAccess } from '@/models/User';

import { PATH_API } from '@/routes';

// ----------------------------------------------------------------------

export type ResourceValueProps = {
  governments: GovernmentsList;
};

export const defaultResource: ResourceValueProps = {
  governments: {},
};

export type ResourceContextProps = ResourceValueProps & {
  onChangeGovts: (governmentsList: GovernmentsList) => void;
};

export const initialResource: ResourceContextProps = {
  ...defaultResource,
  onChangeGovts: () => {},
};
// ----------------------------------------------------------------------

const ResourceContext = createContext(initialResource);

export const useResource2 = () => {
  const context = useContext(ResourceContext);

  if (!context) throw new Error('useResource2 must be use inside ResourceProvider');

  return context;
};

// ----------------------------------------------------------------------

type ResourceProviderProps = {
  children: React.ReactNode;
};

export function ResourceProvider2({ children }: ResourceProviderProps) {
  const { appMeta } = useAccess();
  const [governments, setGovernments] = useState(defaultResource.governments);

  const storageAvailable = localStorageAvailable();

  const [governmentsListRes, getGovernmentsList] = useAxios<
    FormedResponseBody<GovernmentsList>,
    GetGovernmentsListReqBody
  >(
    {
      url: PATH_API.governments.root,
      method: 'POST',
    },
    { manual: true }
  );

  useEffect(() => {
    if (storageAvailable) {
      const governments = defaultResource.governments;

      setGovernments(governments as GovernmentsList);
    }
  }, [storageAvailable]);

  useEffect(() => {
    if (appMeta) {
      /**
       * ログイン情報のorgIdを見て取得する自治体コードリストを選択する。
       */
      (async () => {
        await getGovernmentsList({
          data: { codes: appMeta.orgId ? [appMeta.orgId] : [] },
        });
      })();
    }
  }, [appMeta]);

  useEffect(() => {
    if (dataReady(governmentsListRes)) {
      setGovernments(governmentsListRes.data?.content as GovernmentsList);
    }
  }, [governmentsListRes]);

  const onChangeGovts = useCallback((governmentsList: GovernmentsList) => {
    setGovernments(governmentsList);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      governments,
      onChangeGovts,
    }),
    [governments, onChangeGovts]
  );

  return <ResourceContext.Provider value={memoizedValue}>{children}</ResourceContext.Provider>;
}

// ----------------------------------------------------------------------
