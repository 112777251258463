import { ReactNode } from 'react';

import { Fade, Stack } from '@mui/material';

interface Props {
  in: boolean;
  children: ReactNode;
}

export default function FadeStack(props: Props) {
  return (
    <Fade in={props['in']} unmountOnExit>
      <Stack spacing={2}>{props.children}</Stack>
    </Fade>
  );
}
