import React from 'react';

import { m, AnimatePresence } from 'framer-motion';

import { List, IconButton, ListItem, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { AlertCircleOutline, CloseCircle } from 'mdi-material-ui';

import { UploadMultiFileProps } from '../../../types/upload';
import { Image } from '../../elements';
// ----------------------------------------------------------------------

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
}: UploadMultiFileProps) {
  const hasFile = files.length > 0;

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        {files.map((file, index) => {
          if (showPreview && !file.isDelete) {
            return (
              <ListItem
                key={index}
                component={m.div}
                sx={{
                  p: 0,
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: 1.25,
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-flex',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Image alt="preview" src={file.preview} ratio="1/1" />

                {file.loading && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#f4f4f4c4',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}

                {file.error && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#f4f4f4c4',
                    }}
                  >
                    <AlertCircleOutline sx={{ color: 'red' }} />
                  </Box>
                )}

                {!file.loading && onRemove && (
                  <IconButton
                    size="small"
                    onClick={() => onRemove(file, index)}
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                    }}
                  >
                    <CloseCircle />
                  </IconButton>
                )}
              </ListItem>
            );
          }

          return <React.Fragment key={index}></React.Fragment>;
          /* --- showPreviewを使用しない場合 一旦このケースは考えないのでコメントアウト ---*/
          // return (
          //   <ListItem
          //     key={index}
          //     component={m.div}
          //     sx={{
          //       my: 1,
          //       px: 2,
          //       py: 0.75,
          //       borderRadius: 0.75,
          //       border: (theme) => `solid 1px ${theme.palette.divider}`,
          //     }}
          //   >
          //     <Iconify
          //       icon={'eva:file-fill'}
          //       sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
          //     />

          //     {/* <ListItemText
          //       primary={typeof file === 'string' ? file : name}
          //       secondary={typeof file === 'string' ? '' : fData(size || 0)}
          //       primaryTypographyProps={{ variant: 'subtitle2' }}
          //       secondaryTypographyProps={{ variant: 'caption' }}
          //     /> */}

          //     {onRemove && (
          //       <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
          //         <Iconify icon={'eva:close-fill'} />
          //       </IconButton>
          //     )}
          //   </ListItem>
          // );
        })}
      </AnimatePresence>
    </List>
  );
}
