import jwtDecode from 'jwt-decode';
// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const getPermissionsFromToken = (accessToken: string) => {
  const decodedToken = jwtDecode(accessToken);
  // @ts-ignore jwtが保持する内容は変わる可能性あるので型エラー無視
  return decodedToken.permissions;
};

export { isValidToken, getPermissionsFromToken };
