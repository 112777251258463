import { useEffect, useState } from 'react';

import { m } from 'framer-motion';

import { Container, Typography } from '@mui/material';

import { ForbiddenIllustration } from '../../assets';
import { useAuth, useAppConfig } from '../../hooks';
import { MotionContainer, varBounce } from '../elements';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuth();

  const { roles: ROLES } = useAppConfig();

  const [accessDeny, setAccessDeny] = useState(true);

  const handleAccess = (userRoles: string[]) => {
    if (roles) {
      const hasAccess = roles.some((role: string) => userRoles.indexOf(role) !== -1);
      setAccessDeny(!hasAccess);
      if (userRoles.includes(ROLES?.SYSAD || '')) {
        setAccessDeny(false);
      }
    } else {
      setAccessDeny(true);
    }
  };

  useEffect(() => {
    const userRoles = user?.['https://fysadmin.cloud.jp/roles'];
    handleAccess(userRoles);
  }, [user]);

  if (roles && accessDeny) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            アクセス権限がありません
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
