import React, { ReactElement, ReactNode, Key } from 'react';

import { Card, CardContent, CardContentProps, Tab, Tabs } from '@mui/material';

export interface TabConfig<Tab> {
  key: Tab;
  label: string;
  icon: ReactElement;
}

export interface TabCardProps<Tab> {
  tabs: TabConfig<Tab>[];
  children: ReactNode;
  currentTab: Tab;
  handleChangeTab: (_: any, tab: Tab) => void;
  CardContentProps?: CardContentProps;
}

export default function TabsCard<Tab extends Key | string>({
  tabs,
  currentTab,
  handleChangeTab,
  children,
  CardContentProps,
}: TabCardProps<Tab>) {
  return (
    <Card>
      <Tabs value={currentTab} onChange={handleChangeTab}>
        {tabs.map((tab) => {
          const { key, label, icon } = tab;
          return <Tab key={key} icon={icon} label={label} value={key} />;
        })}
      </Tabs>
      <CardContent {...CardContentProps}>{children}</CardContent>
    </Card>
  );
}
