import { MouseEvent, useState } from 'react';

import { LinearProgress, Popover, alpha, useTheme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColumns,
  GridEnrichedColDef,
  jaJP,
} from '@mui/x-data-grid-pro';

import { useCopyToClipboard, useSnackbars } from '@hui/hooks';

import CustomToolbar, { ICustomToolbarProps } from './CustomToolbar';

export interface IBasicDataGridProps extends DataGridProProps {
  toolbarProps?: ICustomToolbarProps;
  noRowsLabel?: string;
  /**
   * セルコピー無効化
   * type: 'actions' のカラムに関してはコレに関係なく、
   * セルコピー対象外になる
   */
  noCopy?: boolean;
}

export default function BasicDataGrid({
  sx,
  toolbarProps,
  noRowsLabel,
  noCopy = false,
  columns,
  ...others
}: IBasicDataGridProps) {
  const { copy } = useCopyToClipboard();
  const { successSnack } = useSnackbars();
  const theme = useTheme();

  const handleCopyCellData = (params: GridCellParams) => {
    if (noCopy) return;

    const copyText: string | undefined = params.value;
    if (!copyText) return;

    copy(copyText);
    successSnack(`コピーしました。\nコピー内容: ${copyText}`);
  };

  const isCopiable = (column: GridEnrichedColDef) =>
    /** actions カラム、renderCell を持つカラムにはコピーを付加しない */
    column.type !== 'actions' && !column.renderCell;

  const addTooltipToCell = (columns: GridColumns): GridColumns =>
    columns.map((col) => {
      if (isCopiable(col)) {
        col.cellClassName = 'MuiDataGrid-cellCopiable';
      }

      return col;
    });

  const [columnsState] = useState(noCopy ? columns : addTooltipToCell(columns));

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    const field = event.currentTarget.dataset.field;
    if (!!field) {
      const column = columns.find((c) => c.field === field);
      if (column && isCopiable(column)) {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <DataGridPro
        localeText={{
          ...jaJP.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: noRowsLabel || '該当するデータがありません。',
        }}
        sx={{
          height: '80vh',
          '& .MuiDataGrid-cellCopiable': {
            cursor: 'pointer',
            '*': {
              cursor: 'pointer',
            },
            '&:hover': {
              '*': {
                textDecoration: 'underline',
              },
            },
          },
          '& .MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
            borderRight: 'solid 1px #D4D4D4',
          },
          '& .MuiDataGrid-row.Mui-odd': {
            background: theme.palette.grey[200],
          },
        }}
        components={{ Toolbar: CustomToolbar, LoadingOverlay: LinearProgress }}
        componentsProps={{
          toolbar: { ...toolbarProps },
          ...(!noCopy && {
            cell: {
              onMouseEnter: handlePopoverOpen,
              onMouseLeave: handlePopoverClose,
            },
          }),
        }}
        {...(!noCopy && { onCellClick: handleCopyCellData })}
        columns={columnsState}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        {...others}
      />

      <Popover
        sx={{
          pointerEvents: 'none',
          '.MuiPopover-paper': {
            maxWidth: '100vw',
            ...theme.typography.subtitle1,
            backgroundColor: alpha(theme.palette.grey[700], 0.7),
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
            boxShadow: theme.customShadows.z12,
            color: theme.palette.common.white,
            padding: theme.spacing(0.5, 1),
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        クリックしてコピー
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

/**
 * アクションカラム共通の設定
 */
export const commonActionCol = {
  field: 'actions',
  type: 'actions',
  headerName: '操作',
  width: 100,
  disableExport: true,
  hideable: false,
};
