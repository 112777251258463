import React from 'react';

import { Stack, Typography } from '@mui/material';

import DetailCell from './DetailCell';
import TableRowStyled from './TableRowStyled';
import CopiableText from '../CopiableText';

interface DetailRowJointTextProps {
  label: string;
  values: string[] | number[];
  /**
   * value はコピー用にも使われるので、表示用に値を別で指定する場合設定
   */
  renderValues?: string[] | number[];
  /**
   * 2つの値の間に表示する文字列
   */
  connector: string;
  /**
   * ロード中フラグ
   * true の間、値の部分にスケルトン表示
   */
  loading?: boolean;
}

export default function DetailRowJointText({
  label,
  values,
  connector,
  renderValues = values,
  loading = false,
}: DetailRowJointTextProps) {
  const typographyProps = {
    variant: 'body1' as const,
    component: 'span',
  };

  const first = values[0];
  const second = values[1];

  const renderFirst = renderValues[0];
  const renderSecond = renderValues[1];

  return (
    <TableRowStyled>
      <DetailCell
        label={label}
        value={
          <Stack direction="row">
            <CopiableText value={first}>
              <Typography {...typographyProps}>{renderFirst}</Typography>
            </CopiableText>
            <Typography {...typographyProps} sx={{ mx: 1 }}>
              {connector}
            </Typography>
            <CopiableText value={second} palacement="right">
              <Typography {...typographyProps}>{renderSecond}</Typography>
            </CopiableText>
          </Stack>
        }
        loading={loading}
      />
    </TableRowStyled>
  );
}
