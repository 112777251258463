import { useAppConfig } from '../../hooks';

import { CustomAvatar } from '.';

// ----------------------------------------------------------------------
type Props = {
  name: string;
  gcode: string;
};

export default function GovtAvatar({ name, gcode }: Props) {
  const { values } = useAppConfig();
  const url = values.public_storage + `/govt-icons/${gcode}.jpg`;
  return <CustomAvatar url={url} name={name} forColorGen={gcode} />;
}
