import React, { ReactNode } from 'react';

import { Stack, TableCell, Typography, TableCellProps, Skeleton } from '@mui/material';

import { useResponsive } from '@hui/hooks';

interface DetailCellProps extends TableCellProps {
  label: string;
  value: ReactNode;
  /**
   * ロード中フラグ
   * true の間、値の部分にスケルトン表示
   */
  loading?: boolean;
}

export default function DetailCell({ label, value, loading = false, ...other }: DetailCellProps) {
  const isMobile = useResponsive('down', 'md');

  return (
    <>
      <TableCell
        align={isMobile ? 'left' : 'right'}
        sx={{
          width: { xs: '100%', md: '250px' },
          wordBreak: { xs: 'break-word', md: 'normal' },
          textAlign: { xs: 'left', md: 'right' },
        }}
        {...other}
      >
        <Stack spacing={1}>
          <Typography sx={{ height: '100%' }} variant="subtitle2">
            {label}
          </Typography>
          {isMobile && (loading ? <Skeleton /> : value)}
        </Stack>
      </TableCell>

      {!isMobile && (
        <TableCell align="left" sx={{ wordBreak: 'break-word' }}>
          {loading ? <Skeleton /> : value}
        </TableCell>
      )}
    </>
  );
}
