import { GlobalStyles } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function StyledLightbox() {
  const theme = useTheme();

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '.yarl__root': {
          '--yarl__thumbnails_thumbnail_padding': 0,
          '--yarl__thumbnails_thumbnail_border': 'transparent',
          '--yarl__color_backdrop': 'rgba(22, 28, 36, 0.9)',
          '--yarl__slide_captions_container_background': 'rgba(22, 28, 36, 0.48)',
          '& .yarl__container': {
            backgroundColor: alpha(theme.palette.grey[900], 0.9),
          },
          '& .yarl__thumbnails_vignette': {
            '--yarl__thumbnails_vignette_size': 0,
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}
