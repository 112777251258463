import { ReactNode } from 'react';

import { Link, Tooltip, alpha } from '@mui/material';

import { useCopyToClipboard, useSnackbars } from '@hui/hooks';

export interface CopiableTextProps {
  value: string | number | string[];
  children: ReactNode;
  palacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export default function CopiableText({ value, children, palacement = 'left' }: CopiableTextProps) {
  const { copy } = useCopyToClipboard();

  const { successSnack } = useSnackbars();

  const onCopy = (value: string | number | string[]) => {
    if (value) {
      const copyText = Array.isArray(value) ? value.join(', ') : value;
      let snackText = 'コピーしました。';
      if (String(copyText).length <= 10) {
        snackText += `\n${copyText}`;
      }
      successSnack(snackText);
      copy(copyText);
    }
  };

  if (!value) {
    return <>{children}</>;
  }

  return (
    <Tooltip title="クリックでコピー" placement={palacement}>
      <Link
        underline="none"
        color="inherit"
        onClick={() => onCopy(value)}
        component="div"
        sx={{
          cursor: 'pointer',
          '&:hover': {
            '& > *': {
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
              borderRadius: (theme) => (theme.shape.borderRadius as number) / 4,
            },
            '& *': {
              cursor: 'pointer',
            },
          },
        }}
      >
        {children}
      </Link>
    </Tooltip>
  );
}
