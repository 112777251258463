import { ReactNode } from 'react';

import { Card, CardHeader } from '@mui/material';
import { styled } from '@mui/material/styles';

const DisableCardStyled = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  boxShadow: 'none',
  userSelect: 'none',
}));

const CardStyled = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.shorter,
  }),
  boxShadow: theme.customShadows.z8,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    boxShadow: 'none',
  },
  userSelect: 'none',
}));

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(3),
  '.MuiCardHeader-root': {
    width: '100%',
  },
  '.MuiCardHeader-action': {
    margin: 'auto',
  },
}));

interface SelectCardProps {
  isSelected?: boolean;
  title: string;
  onClick?: VoidFunction;
  avatar?: ReactNode;
  subheader?: string;
  action?: ReactNode;
}

interface WrapCard extends Pick<SelectCardProps, 'isSelected' | 'onClick'> {
  children: ReactNode;
}

const WrapCard = ({ isSelected, children, onClick }: WrapCard) =>
  isSelected ? (
    <DisableCardStyled>{children}</DisableCardStyled>
  ) : (
    <CardStyled onClick={onClick}>{children}</CardStyled>
  );

export default function SelectCard({
  isSelected = false,
  avatar,
  title,
  subheader,
  action,
  onClick,
}: SelectCardProps) {
  return (
    <WrapCard {...(onClick && { onClick })} isSelected={isSelected}>
      <CardHeaderStyled
        title={title}
        {...(avatar && { avatar })}
        {...(subheader && { subheader })}
        {...(action && { action })}
      />
    </WrapCard>
  );
}
