import {
  styled,
  Step,
  Stepper as MuiStepper,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepperProps,
  Chip,
  Stack,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';

import { useResponsive } from '../../../hooks';

// ----------------------------------------------------------------------

const ConnectorStyled = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    [theme.breakpoints.down('sm')]: {
      top: 14,
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: theme.palette.divider,
  },
}));

const IconStyled = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
  ...(ownerState.active && {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
  }),
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30,
    '& .MuiSvgIcon-root': {
      width: 20,
    },
  },
}));

const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: '10px',
  '& .MuiLinearProgress-bar': { borderRadius: '8px' },
  '& .MuiLinearProgress-dashed': {
    height: '20px',
    backgroundSize: '20px 20px',
    marginTop: '-2px',
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <IconStyled ownerState={{ completed, active }} className={className}>
      {props.icon}
    </IconStyled>
  );
}

type StepConfig = {
  icon: React.ReactNode;
  label: string;
};

type Props = {
  config: StepConfig[];
  activeStep: number;
  StepperProps?: StepperProps;
  simpleOnMobile?: boolean;
};

export default function Stepper({
  config,
  activeStep,
  StepperProps,
  simpleOnMobile = false,
}: Props) {
  const isMobile = useResponsive('down', 'sm');
  const chipSize = isMobile ? 'small' : 'medium';

  if (simpleOnMobile && isMobile) {
    const stepLength = config.length;

    const getProgress = () => {
      const value = ((activeStep + 1) / stepLength) * 100;
      const valueBuffer = ((activeStep + 2) / stepLength) * 100;

      return {
        value,
        valueBuffer,
      };
    };

    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6" align="center">
            {config[activeStep].label}
          </Typography>
          <Chip label={`${activeStep + 1} / ${stepLength} `} />
        </Stack>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgressStyled variant="buffer" {...getProgress()} />
        </Box>
      </Stack>
    );
  } else {
    return (
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ConnectorStyled />}
        {...StepperProps}
      >
        {config.map(({ icon, label }, i) => (
          <Step key={label}>
            <StepLabel icon={icon} StepIconComponent={ColorlibStepIcon}>
              {i === activeStep ? (
                <Chip label={label} size={chipSize} />
              ) : (
                <Chip disabled label={label} size={chipSize} />
              )}
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    );
  }
}
