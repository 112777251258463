import { ReactNode, createContext } from 'react';

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { NavListProps } from '../types/navSection';

type MenuOption = {
  label: string;
  linkTo: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

export type NavConfig = {
  subheader: string;
  showSubHeader: boolean;
  items: NavListProps[];
};

interface IHeaderAccount {
  menuOptions: MenuOption[];
}

export type ConfigContextProps = {
  values: Record<string, any>;
  headerAccount: IHeaderAccount;
  navItems: NavConfig[];
  getNavItems: (userPermits: string[]) => NavConfig[];
  roles?: Record<string, string>;
};

const initialState: ConfigContextProps = {
  values: {},
  headerAccount: { menuOptions: [] },
  navItems: [],
  getNavItems: () => [],
  roles: {},
};

const ConfigContext = createContext(initialState);

// ----------------------------------------------------------------------

type ConfigProviderProps = {
  children: ReactNode;
  config: ConfigContextProps;
};

function ConfigProvider({ children, config }: ConfigProviderProps) {
  return <ConfigContext.Provider value={{ ...config }}>{children}</ConfigContext.Provider>;
}

// ----------------------------------------------------------------------

export { ConfigProvider, ConfigContext };
