import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';

import { LicenseInfo } from '@mui/x-license-pro';

import { ProgressBar, SnackbarWrapper } from '@hui/components';
import {
  AccessProvider,
  AuthProvider,
  CollapseDrawerProvider,
  ConfigProvider,
} from '@hui/contexts';
import ThemeProvider from '@hui/theme';

import { ResourceProvider2 } from '@/common/hooks/ResourceContext2';
import palette from '@/common/theme/palette';

import { APP_CONFIG, AUTH0_API, MUI } from '@/config';

// ----------------------------------------------------------------------

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

LicenseInfo.setLicenseKey(MUI.pro!);

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, router } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider
        auth0API={{
          domain: AUTH0_API.domain as string,
          clientId: AUTH0_API.clientId as string,
          redirectUri: AUTH0_API.redirectUrl as string,
          audience: AUTH0_API.audience as string,
        }}
      >
        <AccessProvider router={router}>
          <ConfigProvider config={APP_CONFIG}>
            <SnackbarWrapper>
              <ResourceProvider2>
                <ThemeProvider paletteDynamic={palette}>
                  <CollapseDrawerProvider>
                    <ProgressBar />
                    {getLayout(<Component {...pageProps} />)}
                  </CollapseDrawerProvider>
                </ThemeProvider>
              </ResourceProvider2>
            </SnackbarWrapper>
          </ConfigProvider>
        </AccessProvider>
      </AuthProvider>
    </>
  );
}
