export { default as axios } from './axios';
export * from './axios';
export * from './axiosHooks';

export { default as createAvatar } from './createAvatar';
export * from './createAvatar';

export { default as cssStyles } from './cssStyles';
export * from './cssStyles';

export { default as flattenArray } from './flattenArray';
export * from './flattenArray';

export { default as getColorName } from './getColorName';
export * from './getColorName';

export { default as getColorPresets } from './getColorPresets';
export * from './getColorPresets';

export { default as getFileData } from './getFileData';
export * from './getFileData';

export { default as getFontValue } from './getFontValue';
export * from './getFontValue';

export { default as confirmDialog } from './confirmDialog';
export * from './confirmDialog';

export * from './common';
export * from './dataGrid';
export * from './form';
export * from './formatString';
export * from './formatNumber';
export * from './formatString';
export * from './formatTime';
export * from './getFileFormat';
export * from './getSettings';
export * from './jwt';
export * from './localStorageAvailable';
