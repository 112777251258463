import { Controller, FieldError, useFormContext } from 'react-hook-form';

import {
  Stack,
  Chip,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
  styled,
  alpha,
} from '@mui/material';

import { useCheckFieldRequired } from '../../../hooks';
import { IRHFCheckboxSetting } from '../../../types';

const CheckboxStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'blockStyle',
})<{ error?: FieldError; blockStyle: boolean }>(({ theme, error, blockStyle }) => ({
  ...(blockStyle && {
    width: '100%',
    padding: theme.spacing(1),
    marginRight: '0!important',
    transition: theme.transitions.create('all'),
    background: theme.palette.grey[5008],
    ...(error && {
      background: alpha(theme.palette.error.light, theme.palette.action.hoverOpacity),
      color: theme.palette.error.main,
    }),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
  }),
}));

export interface IRHFCheckboxProps {
  input: IRHFCheckboxSetting;
  /** チェックボックスを囲み付きのデザインにする場合 true */
  blockStyle?: boolean;
}

export default function RHFCheckbox({ input, blockStyle = false, ...other }: IRHFCheckboxProps) {
  const { control } = useFormContext();
  const { key, label, labelAlt, helper } = input;
  const required = useCheckFieldRequired(input);

  return (
    <FormControlLabel
      // 別でラベルを指定しているが、無指定だとエラーになるので空文字列指定
      label=""
      sx={{ marginLeft: 0, marginRight: 0 }}
      control={
        <Controller
          name={key}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CheckboxStack spacing={0} error={error} blockStyle={blockStyle}>
              <Stack spacing={0} alignItems="center" direction="row">
                <Checkbox checked={!!field.value} required={required} {...other} {...field} />
                <Typography variant={blockStyle ? 'subtitle2' : 'body2'}>
                  {labelAlt || label}
                </Typography>
                {required && (
                  <Chip
                    size="small"
                    color={error ? 'error' : 'primary'}
                    label="必須"
                    sx={{ marginLeft: '8px' }}
                  />
                )}
              </Stack>
              {helper || error ? (
                <FormHelperText
                  error={!!error}
                  sx={{ marginTop: -1, paddingLeft: '40px', marginBottom: 1 }}
                >
                  {helper} {error && error.message}
                </FormHelperText>
              ) : null}
            </CheckboxStack>
          )}
        />
      }
    />
  );
}
