import RHFGetField from './RHFGetField';
import { FormFieldSettings } from '../../../types';
import { Error } from '../../elements';

// ----------------------------------------------------------------------

interface Props {
  inputs: FormFieldSettings;
  disabled?: boolean;
}

export default function RHFGenFields({ inputs, disabled }: Props) {
  return (
    <>
      {inputs.map((input, index) => {
        if (!input) {
          // Show setting error on UI
          return (
            <Error key={index} message="Field Setting Error: No input">
              {JSON.stringify(input)}
            </Error>
          );
        }

        if ('fieldType' in input) {
          return <RHFGetField key={index} input={input} disabled={disabled} />;
        }
      })}
    </>
  );
}
