import { forwardRef } from 'react';

import { Avatar as MUIAvatar, AvatarProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export interface IAvatarProps extends AvatarProps {
  color?: string;
}

const Avatar = forwardRef<HTMLDivElement, IAvatarProps>(
  ({ color, children, sx, ...other }, ref) => {
    const theme = useTheme();

    if (color === 'default') {
      return (
        <MUIAvatar ref={ref} sx={sx} {...other}>
          {children}
        </MUIAvatar>
      );
    }

    return (
      <MUIAvatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: color ? color : theme.palette.grey[50012],
          backgroundColor: color,
          ...sx,
        }}
        {...other}
      >
        {children}
      </MUIAvatar>
    );
  }
);

export default Avatar;
