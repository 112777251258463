import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormFieldSetting } from '../types';

// ----------------------------------------------------------------------

/**
 * 他のフィールドの入力値に伴って、required を切り替えるカスタムフック
 * conditional で required になるパターンを判定する
 */
export default function useCheckFieldRequired(input: FormFieldSetting) {
  const { getValues } = useFormContext();
  const [required, setRequired] = useState<boolean>(input.required || false);

  const checkRequired = () => {
    const _required = input.condition?.required;
    if (_required) {
      const { field, is } = _required;
      const condition = getValues(field) === is;
      setRequired(condition);
    }
  };

  useEffect(() => {
    checkRequired();
  });

  return required;
}
