import { Context, useContext } from 'react';

import { AccessContext, AccessContextProps } from '../contexts/AccessContext';

// ----------------------------------------------------------------------

const useAccess = <T, U>() =>
  useContext<AccessContextProps<T, U>>(AccessContext as Context<AccessContextProps<T, U>>);

export default useAccess;
