import { motion } from 'framer-motion';

import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

const SIZES = {
  small: {
    icon: 46,
    inner: 70,
    outer: 90,
  },
  medium: {
    icon: 64,
    inner: 100,
    outer: 120,
  },
};

// ----------------------------------------------------------------------

type Props = {
  size?: 'small' | 'medium';
};

export default function LoadingScreen({ size = 'medium' }: Props) {
  return (
    <>
      {/* フヤセルデモのため一旦アイコン非表示 */}
      {/* <motion.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <HarvLogoIcon disabledLink sx={{ width: SIZES[size].icon, height: SIZES[size].icon }} />
      </motion.div> */}

      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: SIZES[size].inner,
          height: SIZES[size].inner,
          borderRadius: '5%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: SIZES[size].outer,
          height: SIZES[size].outer,
          borderRadius: '5%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />
    </>
  );
}
