const normalizeHash = (hash: number, min: number, max: number) =>
  Math.floor((hash % (max - min)) + min);

const generateHSL = (name: string) => {
  const hRange = [0, 360];
  const sRange = [0, 100];
  const lRange = [0, 80];

  const hash = isNaN(Number(name)) ? name.length * 24 : Number(name);
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

const getFirstChar = (name: string) => name && name.charAt(0).toUpperCase();

const getAvatarColor = (name: string) => {
  const hsl = generateHSL(name || '');
  return {
    color: `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`,
    contrast: hsl[2] >= 50 ? '#555555' : '#ffffff',
  };
};

export default function createAvatar(name: string, forColor?: string) {
  const forGenColor = forColor ? forColor : name;
  const { color, contrast } = getAvatarColor(forGenColor);
  return {
    char: getFirstChar(name),
    color,
    contrast,
  } as const;
}
