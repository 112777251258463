import { ReactNode } from 'react';

import { Stack, Box, BoxProps, Typography, Divider } from '@mui/material';

// ----------------------------------------------------------------------

export interface PageHeaderProps extends BoxProps {
  action?: ReactNode;
  heading?: string;
  moreLink?: string | string[];
}

export default function PageHeader({ action, heading, sx, ...other }: PageHeaderProps) {
  return (
    <Stack spacing={2} sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
      <Divider />
    </Stack>
  );
}
