import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { FirebaseStorage } from 'firebase/storage';

import { FormHelperText } from '@mui/material';

import { FormFieldSetting, FileState, UploadMultiFileProps } from '../../../types';
import UploadMultiFile from '../upload/UploadMultiFile';

interface RHFUploadMultiFileProps extends Omit<UploadMultiFileProps, 'files'> {
  input: FormFieldSetting;
  filePath: string;
  filesState: FileState[];
  setFilesState: React.Dispatch<React.SetStateAction<FileState[]>>;
  firebaseStorage: FirebaseStorage;
  removeFunc?: (file: File) => void;
}

const getInitialState = (imageUrls: string[]): FileState[] =>
  imageUrls.map((preview) => ({
    preview,
    loading: false,
    error: false,
    isUploaded: true,
    isDelete: false,
  }));

const updateFileState = (updateIndex: number, newState: FileState, filesState: FileState[]) =>
  filesState.map((state, i) => {
    if (i === updateIndex) return newState;
    return state;
  });

export function RHFUploadMultiFile({
  input,
  filePath = '',
  filesState,
  setFilesState,
  firebaseStorage,
  removeFunc,
  ...other
}: RHFUploadMultiFileProps) {
  const { control, setValue, getValues } = useFormContext();
  const { key } = input;

  const handleDrop = async (acceptedFiles: File[]) => {
    const newFiles = [
      ...filesState,
      ...acceptedFiles.map(
        (file): FileState => ({
          file: file,
          filePath: filePath,
          preview: URL.createObjectURL(file),
          loading: false,
          error: false,
          isUploaded: false,
          isDelete: false,
        })
      ),
    ];
    setFilesState(newFiles);
  };

  const handleRemove = async (file: FileState, index: number) => {
    // FirebaseStorageに上がっているか判定します
    if (file.isUploaded) {
      // Storageに上がっているものはその場で削除せず[isDelete]をtrueにするだけです
      setFilesState((prev) => updateFileState(index, { ...file, isDelete: true }, prev));
    } else {
      // Storageに上がっていないものはその場で削除します
      const filteredItems = filesState.filter((_file) => _file.preview !== file.preview);
      setFilesState(filteredItems);
    }
  };

  useEffect(() => {
    // 初回マウント時にimagesからfilesStateを作成します(編集の際に使用される)
    setFilesState(getInitialState(getValues(key) || []));
  }, []);

  useEffect(() => {
    // filesStateが更新される毎にimagesを併せて更新します
    const uploadedPreviews = filesState.map((f) => f.preview);
    setValue(key, uploadedPreviews);
  }, [filesState]);

  return (
    <Controller
      name={key}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && filesState.filter((file) => !file.isDelete).length === 0;

        return (
          <UploadMultiFile
            accept={{ 'image/*': [] }}
            files={filesState}
            error={checkError}
            onDrop={handleDrop}
            onRemove={handleRemove}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
