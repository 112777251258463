import useAuth from './useAuth';

const useRefreshToken = (audience: string) => {
  const { getAccessTokenSilently } = useAuth();

  const refresh = async () => {
    const accessToken = await getAccessTokenSilently({ audience, scope: 'read:current_user' });
    return accessToken;
  };
  return refresh;
};

export default useRefreshToken;
