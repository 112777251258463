import { ReactNode, SyntheticEvent } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';

import {
  styled,
  RadioGroup,
  Radio,
  Stack,
  Box,
  Typography,
  FormControlLabel,
  alpha,
  FormHelperText,
} from '@mui/material';
import { RadioboxMarked } from 'mdi-material-ui';

import { IRHFRadioSetting } from '../../../types';
import { Error } from '../../elements';

// ----------------------------------------------------------------------

const RadioOptionCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: FieldError }>(({ theme, error }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2.5),
  justifyContent: 'space-between',
  transition: theme.transitions.create('all'),
  background: theme.palette.grey[5008],
  ...(error && {
    background: alpha(theme.palette.error.light, theme.palette.action.hoverOpacity),
    color: theme.palette.error.main,
  }),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

interface OptionProps {
  optionKey: string;
  label: string;
  description?: string;
  error?: FieldError;
  [key: string]: any;
}

export function RHFRadioOption({
  optionKey,
  label,
  description = '',
  error,
  ...other
}: OptionProps) {
  return (
    <RadioOptionCard error={error}>
      <FormControlLabel
        value={optionKey}
        control={
          <Radio checkedIcon={<RadioboxMarked />} color={error ? 'error' : 'primary'} {...other} />
        }
        label={
          <Box sx={{ ml: 1 }}>
            <Typography variant="subtitle2">{label}</Typography>
            {description && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {description}
              </Typography>
            )}
          </Box>
        }
        sx={{ py: 0.5, flexGrow: 1, mr: 0 }}
      />
    </RadioOptionCard>
  );
}

interface GroupOptionsProps {
  input: IRHFRadioSetting;
  error?: FieldError;
}

export function RHFRadioGroupOptions({ input, error }: GroupOptionsProps) {
  const { options = {}, required = false } = input;

  const optionKeys = Object.keys(options);

  if (optionKeys.length === 0) {
    console.error('RHFRadioGroup needs options');
    // Show setting error on UI
    return (
      <Error message="Field Setting Error: RHFRadioGroup needs options">
        {JSON.stringify(input)}
      </Error>
    );
  }

  return (
    <Stack spacing={2} alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
      {optionKeys.map((optionKey) => {
        const { label, description } = options[optionKey];

        const props = {
          optionKey,
          label,
          description,
          required,
          error,
        };

        return <RHFRadioOption key={optionKey} {...props} />;
      })}
    </Stack>
  );
}

export interface IRHFRadioGroupProps {
  input: IRHFRadioSetting;
  children?: ReactNode;
}

export default function RHFRadioGroup({ input, children }: IRHFRadioGroupProps) {
  const { control } = useFormContext();
  const { key, helper } = input;

  /**
   * true false を文字列から真偽値に変換してから値として保持
   */
  const optValue = (event: SyntheticEvent): string | number | boolean => {
    let value: string | number | boolean = (event.target as HTMLInputElement).value;
    if (value === 'true' || value === 'false') {
      value = value.toLowerCase() === 'true';
    }
    return value;
  };

  return (
    <Controller
      name={key}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack spacing={1}>
          <RadioGroup
            {...field}
            onChange={(e) => {
              field.onChange(optValue(e));
            }}
            sx={{
              display: 'block',
            }}
          >
            {children ? children : <RHFRadioGroupOptions input={input} error={error} />}
          </RadioGroup>

          {helper || error ? (
            <FormHelperText error={!!error} sx={{ marginTop: 0, marginLeft: 2 }}>
              {helper} {error && error.message}
            </FormHelperText>
          ) : null}
        </Stack>
      )}
    />
  );
}
