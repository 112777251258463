import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  TextField,
  OutlinedTextFieldProps,
  Autocomplete,
  Chip,
  AutocompleteRenderGetTagProps,
} from '@mui/material';

import { InputLabel, huiInputLabelProps } from '..';
import { useCheckFieldRequired } from '../../../hooks';
import { IRHFAutocompleteSetting, OptionSetting } from '../../../types';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  input: IRHFAutocompleteSetting;
  freeSolo: boolean;
  optimizeItem?: Function;
  filterDuplicate?: boolean;
  renderValue?: Function;
  VALID_CHARS?: RegExp;
  INVALID_CHARS?: RegExp;
}

export default function RHFAutocomplete({
  input,
  freeSolo = false,
  optimizeItem = (v: any) => v,
  filterDuplicate = true,
  renderValue,
  VALID_CHARS,
  INVALID_CHARS,
  ...other
}: Props) {
  const { control, watch, setValue } = useFormContext();
  const { disabled = false } = other;
  const { key, label, placeholder = '', helper = '', options } = input;
  const required = useCheckFieldRequired(input);

  const fieldValue = watch(key);

  const getPlaceHolder = placeholder ? '例: ' + placeholder : placeholder;
  const getHelperText = (helper: string, error: string | undefined) =>
    error ? `${error} ${helper}` : helper;

  const [optionError, setItemError] = useState('');
  const [suggestOptions, setSuggestOptions] = useState<Array<OptionSetting>>([]);

  useEffect(() => {
    if (!!options) {
      // options で条件分岐しておかないと、コンポーネントアンマウント時にフリーズする減少が発生するが原因不明
      const suggest = Object.entries(options).map(([key, value]) => value);
      setSuggestOptions(suggest);
    }
  }, [options]);

  const filterNewItem = (selected: string[]): string[] => {
    let result = selected;
    if (filterDuplicate) {
      const uniq = new Set(result.map((e) => optimizeItem(e)));
      result = Array.from(uniq).filter((e) => e !== '');
    }

    if (VALID_CHARS) {
      result = result.filter((e) => VALID_CHARS.test(e));
    }

    if (INVALID_CHARS) {
      result = result.map((e) => e.toString().replace(INVALID_CHARS, ''));
    }

    return result;
  };

  const handleItemChange = (event: React.SyntheticEvent, selected: string[], reason: string) => {
    setValue(key, filterNewItem(selected));
  };

  const handleInputChange = (event: React.SyntheticEvent) => {
    const _inputValue = (event.target as HTMLInputElement).value;

    if (_inputValue === '') {
      // Clear Error
      setItemError('');
      return;
    }

    // Valid chars
    if (VALID_CHARS) {
      if (!VALID_CHARS.test(_inputValue)) {
        setItemError('入力形式が正しくありません。');
        return;
      }
    }

    // Invalid chars
    if (INVALID_CHARS) {
      const invalidMatched = _inputValue.match(INVALID_CHARS);
      if (invalidMatched && invalidMatched.length !== 0) {
        const showIvalidMatched = [...Array.from(new Set(invalidMatched))]
          .join(',')
          .replace(/\s/gm, '空白');

        setItemError(`「${showIvalidMatched}」は使用できません。自動的に削除されます。`);
        return;
      }
    }

    // Prevent duplicated options
    if (filterDuplicate && fieldValue) {
      const inputDup = Array.from(fieldValue).includes(_inputValue);
      if (inputDup) {
        setItemError('重複した項目を作ることはできません。');
        return;
      }
    }

    setItemError('');
    return;
  };

  return (
    <Controller
      name={key}
      control={control}
      render={({ field }) => (
        <Autocomplete
          fullWidth
          multiple
          id={key}
          key={key}
          options={suggestOptions}
          disabled={disabled}
          value={fieldValue}
          freeSolo={freeSolo}
          disableClearable
          onChange={handleItemChange}
          renderTags={(value, getTagProps: AutocompleteRenderGetTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={renderValue ? renderValue(option) : option}
                {...getTagProps({ index })}
                key={index}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              required={required}
              label={
                <InputLabel error={!!optionError} required={required}>
                  {label}
                </InputLabel>
              }
              InputLabelProps={huiInputLabelProps}
              variant="outlined"
              placeholder={getPlaceHolder}
              helperText={getHelperText(helper, optionError)}
              error={!!optionError}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              onChange={handleInputChange}
            />
          )}
        />
      )}
    />
  );
}
