import React from 'react';

import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';

export default function Tooltip({ children, title, ...other }: TooltipProps) {
  return (
    <MUITooltip arrow={true} placement="top" {...other} title={title}>
      {children}
    </MUITooltip>
  );
}
