import { BackdropProps, Box, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LoadingIcon } from '.';

// ----------------------------------------------------------------------

const ModalStyled = styled(Modal, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: string }>(({ background }) => ({
  ...(background && { background: background }),
}));

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ color }) => ({
  position: 'absolute',
  userSelect: 'none',
  ...(color && { color: color }),
}));

// ----------------------------------------------------------------------

interface LoadingScreenProps extends Omit<BackdropProps, 'open'> {
  iconSize?: 'small' | 'medium';
  background?: string;
  color?: string;
  open?: boolean;
  text?: string;
}

export default function LoadingScreen({
  iconSize = 'medium',
  open = true,
  background,
  color = 'white',
  text,
}: LoadingScreenProps) {
  return (
    <ModalStyled
      background={background}
      open={open}
      sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1000 }}
    >
      <BoxStyled>
        <LoadingIcon size={iconSize} />
        {text && (
          <TypographyStyled
            variant="body1"
            color={color}
            sx={{ marginTop: iconSize === 'small' ? '150px' : '200px' }}
          >
            {text}
          </TypographyStyled>
        )}
      </BoxStyled>
    </ModalStyled>
  );
}
