import { Controller, useFormContext } from 'react-hook-form';

import { TextField, OutlinedTextFieldProps, InputAdornment } from '@mui/material';

import { useCheckFieldRequired } from '@hui/hooks';
import { IRHFTextDisabledSetting } from '@hui/types';

import { InputLabel, huiInputLabelProps } from '..';

export interface IRHFDisabledFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  input: IRHFTextDisabledSetting;
}

export default function RHFDisabledField({ input, onClick, ...other }: IRHFDisabledFieldProps) {
  const { control } = useFormContext();
  const { key, label, startAdornment, endAdornment, fieldType } = input;
  /** disableの際にもconditionでrequiredが変更するパターンがあるため追加 */
  const required = useCheckFieldRequired(input);

  const getStartAdornment = () =>
    startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null;
  const getEndAdornment = () =>
    endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null;

  return (
    <Controller
      name={key}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          id={key}
          key={key}
          fullWidth
          label={
            <InputLabel error={!!error} required={required}>
              {label}
            </InputLabel>
          }
          type={fieldType}
          InputProps={{
            startAdornment: getStartAdornment(),
            endAdornment: getEndAdornment(),
          }}
          InputLabelProps={huiInputLabelProps}
          error={!!error}
          {...other}
          {...field}
          disabled={true}
          onClick={onClick}
        />
      )}
    />
  );
}
