import NextLink from 'next/link';
import React, { forwardRef } from 'react';

import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

const LogoIcon = forwardRef<any, Props>(({ disabledLink = false, sx }, ref) => {
  // OR
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src="https://storage.googleapis.com/harv-public/images/harv_icon.png"
      sx={{ width: 32, height: 32, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <NextLink href="/">{logo}</NextLink>;
});

export default LogoIcon;
