import { ResponseValues } from 'axios-hooks';

import { FormedResponseBody } from '../libs/next';

const isDataEmpty = <TResBody = any>(data: FormedResponseBody<TResBody> | undefined) => {
  if (!data?.content) {
    return true;
  }
  return Array.isArray(data?.content)
    ? data?.content?.length === 0
    : Object.keys(data?.content).length === 0;
};

export const dataReady = <TResBody = any>(
  axiosRes: ResponseValues<FormedResponseBody<TResBody>, any, any>
) => {
  const { loading, data } = axiosRes;

  return !loading && !isDataEmpty(data);
};

export const sendSuccess = <TResBody = any>(
  axiosRes: ResponseValues<FormedResponseBody<TResBody>, any, any>
) => {
  const { loading, data, error } = axiosRes;

  return !loading && data && !error && !data.hasError;
};

export const noResData = <TResBody = any>(
  axiosRes: ResponseValues<FormedResponseBody<TResBody>, any, any>
) => {
  const { loading, data } = axiosRes;

  return !loading && data && isDataEmpty(data);
};

export const extractResData = <TResBody = any>(
  axiosRes: ResponseValues<FormedResponseBody<TResBody>, any, any>
) => (dataReady(axiosRes) ? axiosRes.data?.content : false);

export const fetchError = <TResBody = any>(
  axiosRes: ResponseValues<FormedResponseBody<TResBody>, any, any>
) => {
  const { error } = axiosRes;

  return !!error;
};
