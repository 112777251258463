import { Router } from 'next/router';
import { ReactNode, createContext, useEffect, useState, useContext, Context } from 'react';

import { User } from '@auth0/auth0-spa-js';

import { useAuth } from '../hooks';

export interface AccessContextProps<T = Record<string, any>, U = Record<string, any>> {
  user?: User;
  userPermissions: string[];
  history: string[];
  roles: string[];
  userMeta: T;
  appMeta: U;
}

const initialState: AccessContextProps = {
  userPermissions: [],
  history: [],
  roles: [],
  userMeta: {},
  appMeta: {},
};

// ----------------------------------------------------------------------

const AccessContext = createContext(initialState);

export const useAccess = <T, U>() => {
  const context = useContext<AccessContextProps<T, U>>(
    AccessContext as Context<AccessContextProps<T, U>>
  );

  if (!context) throw new Error('useAccess must be use inside AccessProvider');

  return context;
};

// ----------------------------------------------------------------------

interface IAccessProviderProps {
  children: ReactNode;
  router: Router;
}

interface ICustomClaims<TUserMeta, TAppMeta> {
  /** ユーザーロールの文字列配列 e.g. ['harv/agency/staff'] */
  roles: string[];
  /** ユーザーパーミッションの文字列配列 e.g. ['read/makers', 'read:products'] */
  permissions: string[];
  /** Auth0 user_metadata のオブジェクト e.g. { family_kana: 'タナカ', given_kana: 'タロウ' } */
  user_metadata: TUserMeta;
  /** Auth0 app_metadata のオブジェクト e.g. { orgId: 'xxxxx' }  */
  app_metadata: TAppMeta;
}

function AccessProvider<
  TUserMeta extends Record<string, any>,
  TAppMeta extends Record<string, any>
>({ children, router }: IAccessProviderProps) {
  const { user } = useAuth();

  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [userMeta, setUserMeta] = useState<TUserMeta>({} as TUserMeta);
  const [appMeta, setAppMeta] = useState<TAppMeta>({} as TAppMeta);

  const [history, setHistory] = useState([router.asPath, '']);

  useEffect(() => {
    setHistory([router.asPath, history[0]]);
  }, [router.asPath]);

  useEffect(() => {
    if (user) {
      /**
       * 以下の実装は、Auth0 Actions の Login / Post Login Custom Action にて、
       * idToken にカスタムクレームで permissions, roles, user_metadata, app_metadata を持つ
       * オブジェクト追加されていることが前提
       */
      const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE || '';
      const customClaims = user[namespace] as ICustomClaims<TUserMeta, TAppMeta>;
      setUserPermissions(customClaims.permissions);
      setRoles(customClaims.roles);
      setUserMeta(customClaims.user_metadata);
      setAppMeta(customClaims.app_metadata);
    }
  }, [user]);

  return (
    <AccessContext.Provider value={{ user, userPermissions, history, roles, userMeta, appMeta }}>
      {children}
    </AccessContext.Provider>
  );
}

// ----------------------------------------------------------------------

export { AccessProvider, AccessContext };
