import { List, Box } from '@mui/material';

import NavList from './NavList';
import { ListSubheaderStyle } from './style';
import { useAccess, useAppConfig } from '../../../../hooks';
import { NavSectionProps } from '../../../../types/navSection';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ isCollapse, ...other }: NavSectionProps) {
  const { getNavItems } = useAppConfig();
  const { userPermissions } = useAccess();

  return (
    <Box {...other}>
      {getNavItems(userPermissions).map((group) => {
        const { subheader, showSubHeader, items } = group;

        return (
          <List key={subheader} disablePadding sx={{ pr: 2 }}>
            {showSubHeader && (
              <ListSubheaderStyle
                disableGutters
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {subheader}
              </ListSubheaderStyle>
            )}

            {items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChildren={!!list.children}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
