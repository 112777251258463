import { Typography } from '@mui/material';

/**
 * 文字列がないときに未入力の表示をレンダリング
 */
export default function EmptyValueCell() {
  return (
    <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.disabled }}>
      -
    </Typography>
  );
}
