import { Fragment, ReactNode } from 'react';

import { AnimatePresence } from 'framer-motion';

import { TransitionWrapper, AnimDirection } from '../..';

interface StepperContentsProps {
  activeStep: number;
  contents: Record<string, ReactNode>;
  animation?: boolean;
  animVariant?: AnimDirection;
}

export default function StepperContents({
  activeStep,
  contents,
  animation = true,
  animVariant,
}: StepperContentsProps) {
  return (
    <AnimatePresence onExitComplete={() => window.scrollTo(0, 0)}>
      {Object.entries(contents).map(([key, content], i) => (
        <Fragment key={i}>
          {activeStep === i &&
            (animation ? (
              <TransitionWrapper variant={animVariant || 'inLeft'} key={key}>
                {content}
              </TransitionWrapper>
            ) : (
              content
            ))}
        </Fragment>
      ))}
    </AnimatePresence>
  );
}
