import { Chip } from '@mui/material';

export type IStatusChipConfig = Record<
  string,
  {
    label: string;
    color:
      | 'default'
      | 'error'
      | 'success'
      | 'warning'
      | 'info'
      | 'primary'
      | 'secondary'
      | undefined;
  }
>;

export interface IStatusChipProps {
  status: string;
  config: Record<
    string,
    {
      label: string;
      color:
        | 'default'
        | 'error'
        | 'success'
        | 'warning'
        | 'info'
        | 'primary'
        | 'secondary'
        | undefined;
    }
  >;
  size?: 'small' | 'medium';
}

export default function StatusChip({ status, config, size = 'small' }: IStatusChipProps) {
  const _config = config[status];

  if (!_config) return <Chip color="default" size={size} label={status} />;

  const color = _config.color;
  const label = _config.label;

  return <Chip color={color} size={size} label={label} />;
}
