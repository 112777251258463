import { AxiosError } from 'axios';
import { ResponseValues } from 'axios-hooks';
import { useSnackbar, OptionsObject, VariantType } from 'notistack';

import { FormedErrorResponseArgs, NextError } from '@hui/libs/next';
export interface SwitchSnackArgs {
  isSuccess: boolean;
  subject: string;
}
/**
 * 用途ごとの enqueueSnackbar を実行する関数を取り出す
 * @returns
 */
export default function useSnackbars() {
  const { enqueueSnackbar } = useSnackbar();

  const baseEnqueueSnackbar = (variant: VariantType, message: string, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      variant,
      ...(options && { ...options }),
    });

  const errorSnack = (message: string, options?: OptionsObject) =>
    baseEnqueueSnackbar('error', message, options);
  const successSnack = (message: string, options?: OptionsObject) =>
    baseEnqueueSnackbar('success', message, options);
  const infoSnack = (message: string, options?: OptionsObject) =>
    baseEnqueueSnackbar('info', message, options);
  const warnSnack = (message: string, options?: OptionsObject) =>
    baseEnqueueSnackbar('warning', message, options);
  const defaultSnack = (message: string, options?: OptionsObject) =>
    baseEnqueueSnackbar('default', message, options);

  /**
   * 開発用にサーバーサイドのエラーを見やすく出力
   */
  const errorFormatedToConsole = (error: AxiosError<FormedErrorResponseArgs, any>) => {
    if (!error?.response?.data?.error) {
      return;
    }
    const { name, message, stack } = error?.response?.data?.error as NextError;
    console.log('[ERROR]', name);
    console.log(message);
    console.log(stack);
  };

  return {
    errorSnack,
    successSnack,
    infoSnack,
    warnSnack,
    defaultSnack,
    switchSnack: ({ isSuccess, subject }: SwitchSnackArgs) => {
      if (isSuccess) {
        successSnack(`${subject}に成功しました。`);
      } else {
        errorSnack(`${subject}に失敗しました。`);
      }
    },
    /**
     * useAxiosRes を渡すことで、適切なスナックバーを表示
     * 例:
     * {subject}に失敗しました。
     * {subject}に成功しました。
     * @param subject 行う処理の名称 例: 〇〇の一覧取得、〇〇の新規作成
     * @param useAxiosRes useAxios のレスポンス
     * @param onSuccess 処理成功時にスナックバーを表示するかどうか デフォルトはfalse
     */
    dataResSnack: (
      subject: string,
      useAxiosRes: ResponseValues<any, any, any>,
      onSuccess = false
    ) => {
      if (!useAxiosRes.loading) {
        console.log('[INFO] dataResSnack', subject, useAxiosRes);
        const { data, error } = useAxiosRes;

        switch (true) {
          case data && !error && !data.hasError && onSuccess: {
            successSnack(`${subject}に成功しました。`);
            break;
          }
          case error === null: {
            break;
          }
          case !!error || !data || data?.hasError: {
            let errorMsg = `${subject}に失敗しました。\n${useAxiosRes.error}`;
            if (data?.hasError) errorMsg += `\n${data?.hasError}`;

            errorFormatedToConsole(error as AxiosError<FormedErrorResponseArgs, any>);

            errorSnack(errorMsg);
            break;
          }
          case useAxiosRes.data?.result: {
            infoSnack('該当するデータはありません。');
            break;
          }
          default: {
            warnSnack('実装考慮漏れエラー');
            break;
          }
        }
      }
    },
  };
}
