import { FieldError } from 'react-hook-form';

import { Card, Chip, FormHelperText, Typography } from '@mui/material';

import { getHelperText } from '@hui/utils';

export interface IRHFHelperProps {
  placeholder: string;
  helper: string;
  focused: boolean;
  error?: FieldError;
}

/**
 * プレースホルダーやヘルパーテキストを見やすくフィールド下部に表示
 * TODO: [ikeda] フォーカスが当たると表示され、外れると隠れる仕様だが、入力例が長いと画面の動きが激しく見ずらい。
 * フォーカスが外れても非表示にしないと消えないようにする
 */
export default function RHFHelper({ helper, placeholder, focused, error }: IRHFHelperProps) {
  const isShort = placeholder.length <= 30;
  const hasBreak = placeholder.includes('\n');
  return (
    <Card sx={{ position: 'absolute', p: 1, zIndex: 10000000, mt: -10 }}>
      {(helper || error?.message) && focused && (
        <FormHelperText sx={{ margin: '8px 14px 0 14px', ...(!!error && { color: 'error.main' }) }}>
          <Typography
            component={isShort ? 'span' : 'p'}
            sx={{ marginTop: (theme) => theme.spacing(1) }}
            variant="caption"
          >
            {getHelperText(helper, error?.message)}
          </Typography>
        </FormHelperText>
      )}
      {placeholder && focused && (
        <FormHelperText
          component="div"
          sx={{
            padding: (theme) => theme.spacing(1.5),
            whiteSpace: 'pre-wrap',
            ...(!!error && { color: 'error.main' }),
          }}
        >
          <Chip
            size="small"
            label="入力例"
            color={error ? 'error' : 'default'}
            sx={{ margin: (theme) => theme.spacing(0, 1, hasBreak ? 0.5 : 0, 0) }}
          />
          <Typography
            component={isShort ? 'span' : 'p'}
            sx={{ marginTop: (theme) => theme.spacing(1) }}
            variant="caption"
          >
            {placeholder}
          </Typography>
        </FormHelperText>
      )}
    </Card>
  );
}
