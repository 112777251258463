import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StackStyled = styled(Stack)(() => ({
  maxWidth: '760px',
  margin: 'auto',
  textAlign: 'center',
}));

export default function FormWrapper(props: StackProps) {
  return <StackStyled {...props}>{props.children}</StackStyled>;
}
