import { ReactNode } from 'react';

import { motion } from 'framer-motion';

import { Box, Stack, StackProps } from '@mui/material';

import { varFade } from '../elements/animate';

const fadeVariants = varFade({ distance: 120 });

export type AnimDirection =
  | 'in'
  | 'inUp'
  | 'inDown'
  | 'inLeft'
  | 'inRight'
  | 'out'
  | 'outUp'
  | 'outDown'
  | 'outLeft'
  | 'outRight';

type Props = {
  children: ReactNode;
  variant?: AnimDirection;
  StackProps?: StackProps;
};

export default function TransitionWrapper({ children, variant = 'inLeft', StackProps }: Props) {
  return (
    <Box
      component={motion.div}
      {...fadeVariants[variant]}
      transition={{ type: 'linear' }}
      sx={{ width: '100%' }}
    >
      <Stack {...StackProps}>{children}</Stack>
    </Box>
  );
}
