import { confirmable, createConfirmation } from 'react-confirm';

import { ConfirmDialog, ConfirmProps } from '../components';

const confirm = ({ body, title, okLabel, cancelLabel }: ConfirmProps) =>
  createConfirmation(confirmable(ConfirmDialog))({
    body,
    title,
    okLabel,
    cancelLabel,
  });

export default confirm;
