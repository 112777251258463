import { ReactNode } from 'react';

import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  Stack,
  styled,
  TypographyVariant,
} from '@mui/material';

const StackStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant?: TypographyVariant | 'inherit';
}>(({ variant = 'body1', theme }) => {
  const _variant = variant && variant !== 'inherit' && theme.typography[variant];
  if (!_variant) return {};

  const fontSize = (_variant?.fontSize || '') as string;

  return {
    '& .MuiSvgIcon-root': {
      fontSize: Number(fontSize.replace('rem', '')) * 1.5 + 'rem',
      opacity: 0.6,
      height: 'inherit',
    },
  };
});

export interface TypographyProps extends MuiTypographyProps {
  iconStart?: ReactNode;
}

export default function Typography({ children, iconStart, variant, ...other }: TypographyProps) {
  return iconStart ? (
    <StackStyled direction="row" spacing={1} variant={variant}>
      {iconStart}
      <MuiTypography {...other} variant={variant}>
        {children}
      </MuiTypography>
    </StackStyled>
  ) : (
    <MuiTypography {...other} variant={variant}>
      {children}
    </MuiTypography>
  );
}
