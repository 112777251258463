import { Avatar } from '..';
import createAvatar from '../../utils/createAvatar';

// ----------------------------------------------------------------------
type Props = {
  name: string;
  forColorGen?: string;
  url?: string;
};

export default function CustomAvatar({ name, forColorGen, url }: Props) {
  const avatar = createAvatar(name, forColorGen);

  return (
    <Avatar src={url} alt={name} color={avatar.color} sx={{ color: avatar.contrast }}>
      {avatar.char}
    </Avatar>
  );
}
