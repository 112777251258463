import React from 'react';

import { Typography } from '@mui/material';

import { FormFieldSetting } from '@hui/types';

import DetailCell from './DetailCell';
import EmptyValueCell from './EmptyValueCell';
import TableRowStyled from './TableRowStyled';
import CopiableText from '../CopiableText';

type IDetailRowValue = string | string[] | number;
interface IDetailRowTextProps {
  field: FormFieldSetting;
  value: IDetailRowValue | undefined;
  /**
   * value はコピー用にも使われるので、表示用に値を別で指定する場合設定
   */
  renderValue?: IDetailRowValue;
  /**
   * テーブルの項目名を別途指定したい場合に設定
   */
  label?: string;
  /**
   * ロード中フラグ
   * true の間、値の部分にスケルトン表示
   */
  loading?: boolean;
}

export default function DetailRowText({
  field,
  value,
  renderValue = value,
  label = field.label,
  loading = false,
}: IDetailRowTextProps) {
  /**
   * 1行のテキストもしくは数値用
   */
  const SingleLineText = function ({ value }: { value: string | number }) {
    if (value === '' || !value) {
      return <EmptyValueCell />;
    }

    return (
      <Typography variant="body1" component="span">
        {value}
        {field.fieldType === 'text' && field.endAdornment}
      </Typography>
    );
  };

  /**
   * 改行を含むテキスト用
   */
  const MultilineText = function ({ lines }: { lines: string[] }) {
    if (lines.length === 0) {
      return <EmptyValueCell />;
    }

    return (
      <>
        {lines.map((line, i) => (
          <SingleLineText key={i} value={line} />
        ))}
      </>
    );
  };

  const getOptRenderedValue = (field: FormFieldSetting, value: IDetailRowValue) => {
    // 文字列の配列 及び 文字列で複数行のもの
    if (
      Array.isArray(value) ||
      (typeof value === 'string' &&
        field.fieldType === 'text' &&
        field.dataType === 'string' &&
        field.multiline)
    ) {
      const lines = Array.isArray(value) ? value : value.split('\n');
      return <MultilineText lines={lines} />;
    }

    // セレクト (options の中から選択式のものをテキストで表示)
    if ((field.fieldType === 'select' || field.fieldType === 'radio') && !!field.options) {
      const label = field.options[value]?.label || value;
      return <SingleLineText value={label} />;
    }

    // 一行の文字列と数値
    return <SingleLineText value={value} />;
  };

  return (
    <TableRowStyled>
      <DetailCell
        label={label}
        value={
          <CopiableText value={value || ''}>
            {getOptRenderedValue(field, renderValue || '')}
          </CopiableText>
        }
        loading={loading}
      />
    </TableRowStyled>
  );
}
