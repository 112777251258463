import React from 'react';

import { Alert, AlertTitle } from '@mui/material';

type Props = {
  message: string;
  children?: React.ReactNode;
};

export default function Error({ message, children }: Props) {
  return (
    <Alert severity="error" sx={{ textAlign: 'left' }}>
      <AlertTitle>{message}</AlertTitle>
      {children}
    </Alert>
  );
}
