import { ReactNode, useState } from 'react';

import { Collapse, IconButton } from '@mui/material';
import { ChevronDown, ChevronUp } from 'mdi-material-ui';

import CardStack, { CardStackProps } from './CardStack';

interface CardCollapseProps extends CardStackProps {
  title: string;
  // CardHeader のアイコン
  avatar?: ReactNode;
  // レンダリング時に開いておくか折りたたむか
  initialState?: boolean;
  // 閉じた状態でもコンテンツの一部を見せたい場合にその高さを設定
  collapsedSize?: string;
  children: ReactNode;
}

export default function CardCollapse({
  title,
  avatar,
  initialState = true,
  collapsedSize,
  children,
  CardProps,
  CardHeaderProps,
  CardContentProps,
}: CardCollapseProps) {
  const [open, setOpen] = useState(initialState);

  const handleCollapse = () => {
    setOpen(!open);
  };

  function ToggleButton() {
    return (
      <IconButton onClick={handleCollapse}>{open ? <ChevronUp /> : <ChevronDown />}</IconButton>
    );
  }

  return (
    <CardStack
      title={title}
      CardProps={CardProps}
      CardHeaderProps={{
        avatar,
        action: <ToggleButton />,
        ...CardHeaderProps,
        // CardHeader クリックでCollapseできるようにする
        onClick: handleCollapse,
      }}
      CardContentProps={{
        sx: {
          ...(!open && {
            // Collapse されているときにpadding-topをなくす
            pt: '0!important',
            // Collapse のアニメーション速度に padding の変化速度も合わせる
            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          }),
        },
        ...CardContentProps,
      }}
    >
      <Collapse in={open} collapsedSize={collapsedSize}>
        {children}
      </Collapse>
    </CardStack>
  );
}
