import { SyntheticEvent } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import ja from 'date-fns/locale/ja';

import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getHelperText } from '@hui/utils';

import { InputLabel, huiInputLabelProps } from '..';
import { useCheckFieldRequired } from '../../../hooks';
import { IRHFDateTimeSetting } from '../../../types';

interface Props {
  input: IRHFDateTimeSetting;
  disabled?: boolean;
}

export default function RHFDatePicker({ input, ...other }: Props) {
  const { control } = useFormContext();
  const { key, label, helper = '', disabled, fieldType } = input;
  const required = useCheckFieldRequired(input);

  const handleBlur = (e: SyntheticEvent, callback: Function) => {
    callback(e);
  };

  const getHelper = (error?: FieldError): string => `${getHelperText(helper, error?.message)}`;

  const withTime = fieldType === 'datetime';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Controller
        name={key}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DesktopDatePicker
            mask={withTime ? '____/__/__ __:__' : '____/__/__'}
            inputFormat={withTime ? 'yyyy/MM/dd H:mm' : 'yyyy/MM/dd'}
            toolbarTitle="日付を選択してください"
            toolbarFormat={withTime ? 'yyyy年MM月dd日 H:mm' : 'yyyy年MM月dd日'}
            openTo="year"
            views={['year', 'month', 'day']}
            disabled={disabled}
            disableMaskedInput // TODO: remove this with resolve mask problem
            {...field}
            renderInput={(props: TextFieldProps) => (
              <TextField
                {...props}
                fullWidth
                required={required}
                error={!!error}
                onBlur={(e) => {
                  handleBlur(e, field.onBlur);
                }}
                helperText={getHelper(error)}
                label={
                  <InputLabel error={!!error} required={required}>
                    {label}
                  </InputLabel>
                }
                InputLabelProps={huiInputLabelProps}
              />
            )}
            {...other}
          />
        )}
      />
    </LocalizationProvider>
  );
}
