import React from 'react';

import { Chip, Box } from '@mui/material';
import { Check } from 'mdi-material-ui';

import { useResponsive } from '@hui/hooks';
import { IRHFFieldHasOptions } from '@hui/types';

import DetailCell from './DetailCell';
import EmptyValueCell from './EmptyValueCell';
import TableRowStyled from './TableRowStyled';
import CopiableText from '../CopiableText';

type IValueForRowSelect = string | string[] | boolean;

interface IDetailRowSelectProps {
  // TODO: options が必ず保持される方に修正。FormFieldSetting の分割再定義必要
  field: IRHFFieldHasOptions;
  value: IValueForRowSelect;
  /**
   * ロード中フラグ
   * true の間、値の部分にスケルトン表示
   */
  loading?: boolean;
}

export default function DetailRowSelect({ field, value, loading = false }: IDetailRowSelectProps) {
  const { options, label } = field;

  const isDesktop = useResponsive('up', 'md');

  const getCopyValues = (value: IValueForRowSelect): string => {
    const res = Array.isArray(value)
      ? value.map((v) => (options?.[v] ? options?.[v].label : ''))
      : options?.[String(value)]
      ? options?.[String(value)].label
      : '未選択';

    return res as string;
  };

  const Selected = function ({ value }: { value: IValueForRowSelect }) {
    if (!options) {
      return <>Error</>;
    }

    if (value === '') {
      return <EmptyValueCell />;
    }

    const ary = Object.entries(options);
    const aryGrouped = [];

    const step = isDesktop ? 8 : 6;
    for (let i = 0; i < ary.length; i += step) {
      aryGrouped.push(ary.slice(i, i + step));
    }

    return (
      <CopiableText value={getCopyValues(value)}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
          {ary.map(([key, val]) => {
            const selected = Array.isArray(value)
              ? value.includes(String(key))
              : String(value) == String(key);

            return (
              <Chip
                key={key}
                label={val.label}
                sx={{ margin: 0.5 }}
                {...(selected
                  ? {
                      color: 'primary',
                      icon: <Check />,
                    }
                  : {
                      variant: 'outlined',
                    })}
              />
            );
          })}
        </Box>
      </CopiableText>
    );
  };

  return (
    <TableRowStyled>
      <DetailCell label={label} value={<Selected value={value} />} loading={loading} />
    </TableRowStyled>
  );
}
