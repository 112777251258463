import React from 'react';

import { Check, Close } from 'mdi-material-ui';

import { FormFieldSetting } from '@hui/types';

import DetailCell from './DetailCell';
import TableRowStyled from './TableRowStyled';

interface DetailRowBoolProps {
  field: FormFieldSetting;
  value: boolean;
  /**
   * ロード中フラグ
   * true の間、値の部分にスケルトン表示
   */
  loading?: boolean;
}

export default function DetailRowBool({ field, value, loading = false }: DetailRowBoolProps) {
  return (
    <TableRowStyled>
      <DetailCell label={field.label} value={value ? <Check /> : <Close />} loading={loading} />
    </TableRowStyled>
  );
}
