import React, { ReactNode, useEffect, useState } from 'react';

import axios from 'axios';

import useRefreshToken from '@hui/hooks/useRefreshToken';

import { useAuth } from '../../hooks';
import { LoadingScreen } from '../elements';

type Props = {
  auth0Audience: string;
  children: ReactNode;
};

const AuthGuard = ({ auth0Audience, children }: Props) => {
  const { isAuthenticated, error, isLoading, logout, loginWithRedirect, user } = useAuth();

  const [tokenSetting, setTokenSetting] = useState(false);
  const fetchToken = useRefreshToken(auth0Audience);

  const setAuthToken = async () => {
    setTokenSetting(true);
    const token = await fetchToken();
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    setTokenSetting(false);
  };

  useEffect(() => {
    if (!user) return;
    setAuthToken();
  }, [user]);

  if (tokenSetting) return <LoadingScreen background="white" />;

  if (isLoading) return <LoadingScreen background="white" />;

  if (error) {
    logout();
    return <LoadingScreen background="white" />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <LoadingScreen background="white" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
