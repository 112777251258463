import React from 'react';

import { Card, CardProps, Link } from '@mui/material';

interface Props extends CardProps {
  onClick: VoidFunction;
  children: React.ReactNode;
}

export default function CardClickable({ onClick, children, ...other }: Props) {
  return (
    <Link underline="none" component={'div'} onClick={onClick}>
      <Card {...other}>{children}</Card>
    </Link>
  );
}
