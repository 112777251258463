import { Skeleton as MUISkeleton, styled, SxProps, Typography } from '@mui/material';

const MUISkeletonStyled = styled(MUISkeleton)(() => ({
  maxWidth: '100%',
  borderRadius: '12px',
  '&.MuiSkeleton-root > *': {
    visibility: 'visible',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.3,
  },
}));

type Props = {
  height: string;
  sx?: SxProps;
};

export default function Skeleton({ height, sx }: Props) {
  return (
    <MUISkeletonStyled variant="rectangular" animation="wave" sx={{ height, ...sx }}>
      <Typography variant="body1">Loading...</Typography>
    </MUISkeletonStyled>
  );
}
