import { useState } from 'react';

import axios from 'axios';

export type RepositoryData<T> = {
  data: T;
  loading: boolean;
  error: boolean;
};
export type MutationOptions = {
  variables?: {
    [key: string]: any;
  };
};

type MutationType<T> = (url: string, option: MutationOptions) => Promise<T>;
type Repository<T> = [RepositoryData<T>, MutationType<T>];

export default function useRepository<T>(initialState: T): Repository<T> {
  const [state, setState] = useState<RepositoryData<T>>({
    data: initialState,
    loading: false,
    error: false,
  });

  async function mutation(url: string, option: MutationOptions) {
    setState((prev) => ({
      ...prev,
      loading: true,
      error: false,
    }));

    try {
      const res = await axios.post(url, option.variables);
      setState({
        data: res.data,
        loading: false,
        error: false,
      });

      return res.data as T;
    } catch (e: any) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: true,
      }));
      throw e;
    }
  }

  return [state, mutation];
}
