import { Box, ListItemButtonProps } from '@mui/material';
import { ChevronDown, ChevronRight, CircleMedium } from 'mdi-material-ui';

import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { Tooltip } from '../../..';
import { NavItemProps } from '../../../../types/navSection';

// ----------------------------------------------------------------------

type Props = NavItemProps & ListItemButtonProps;

export default function NavItem({ item, depth, active, open, isCollapse, ...other }: Props) {
  const { title, info, children, disabled, caption } = item;

  return disabled ? null : (
    <ListItemStyle depth={depth} active={active} disabled={disabled} {...other}>
      {depth === 1 ? (
        <ListItemIconStyle>
          {typeof item.icon !== 'undefined' ? <item.icon /> : <CircleMedium />}
        </ListItemIconStyle>
      ) : (
        <DotIcon active={active && depth !== 1} />
      )}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={title}
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {!isCollapse && (
        <>
          {info && (
            <Box component="span" sx={{ lineHeight: 0 }}>
              {info}
            </Box>
          )}

          {!!children && (open ? <ChevronDown /> : <ChevronRight />)}
        </>
      )}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
