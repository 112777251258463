import { FormFieldSetting } from '../../../types';
import { Error } from '../../elements';

import {
  IRHFCheckboxProps,
  IRHFTextFieldProps,
  RHFCheckbox,
  RHFDatePicker,
  RHFDisabledField,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
} from '.';

// ----------------------------------------------------------------------

export type IRHFGetFieldProps = {
  input: FormFieldSetting;
  [key: string]: any;
} & Omit<IRHFCheckboxProps, 'input'> &
  Omit<IRHFTextFieldProps, 'input'>;

export default function RHFGetField({ input, ...other }: IRHFGetFieldProps) {
  switch (input.fieldType) {
    case 'select':
      return <RHFSelect input={input} {...other} />;
    case 'checkbox':
      return <RHFCheckbox input={input} {...other} />;
    case 'radio':
      return <RHFRadioGroup input={input} {...other} />;
    case 'date' || 'datetime':
      return <RHFDatePicker input={input} {...other} />;
    case 'disabled':
      return <RHFDisabledField input={input} {...other} />;
    case 'text':
      return <RHFTextField input={input} {...other} />;
    default:
      return <Error message="Unknown Type Field">{JSON.stringify(input)}</Error>;
  }
}
