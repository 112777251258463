import { AvatarProps } from '@mui/material';

import { Avatar } from '..';
import { useAuth } from '../../hooks';
import createAvatar from '../../utils/createAvatar';

import type { User } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();
  const { nickname = '', picture, name } = user as User;

  const avatar = createAvatar(nickname);

  return (
    <Avatar src={picture} alt={name} color={avatar.color} {...other}>
      {avatar.char}
    </Avatar>
  );
}
