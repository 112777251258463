import { styled, TableRow } from '@mui/material';

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default TableRowStyled;
