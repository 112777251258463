import ReactLightbox, { LightboxExternalProps } from 'yet-another-react-lightbox';
import { Thumbnails, Zoom } from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import StyledLightbox from './styles';

export default function Lightbox(props: LightboxExternalProps) {
  return (
    <>
      <StyledLightbox />
      <ReactLightbox
        {...props}
        plugins={[Thumbnails, Zoom]}
        controller={{
          touchAction: 'pan-y',
          closeOnBackdropClick: true,
        }}
      />
    </>
  );
}
