import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

export interface ICustomToolbarProps {
  colmun?: boolean;
  filter?: boolean;
  density?: boolean;
  exporting?: boolean;
}

export default function CustomToolbar({
  colmun = true,
  filter = true,
  density = true,
  exporting = false,
}: ICustomToolbarProps) {
  return (
    <GridToolbarContainer>
      {colmun && <GridToolbarColumnsButton />}
      {filter && <GridToolbarFilterButton />}
      {density && <GridToolbarDensitySelector />}
      {exporting && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
