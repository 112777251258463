import { Controller, useFormContext } from 'react-hook-form';

import { styled, MenuItem, TextField, Checkbox, Box, Chip, Typography, Stack } from '@mui/material';

import { IRHFSelectSetting } from '@hui/types';
import { getHelperText } from '@hui/utils';

import { huiInputLabelProps, InputLabel } from '..';
import { useCheckFieldRequired } from '../../../hooks';
import { Error } from '../../elements';

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  whiteSpace: 'normal',
}));

export interface IRHFSelectProps {
  input: IRHFSelectSetting;
}

export default function RHFSelect({ input, ...other }: IRHFSelectProps) {
  const { control } = useFormContext();
  const { key, label, helper = '', options = {}, dataType = 'string' } = input;

  const required = useCheckFieldRequired(input);

  const isMultiple = dataType === 'array';

  const optionKeys = Object.keys(options);

  if (optionKeys.length === 0) {
    console.error('RHFSelect needs options');
    // Show setting error on UI
    return (
      <Error message="Field Setting Error: RHFSelect needs options">{JSON.stringify(input)}</Error>
    );
  }

  const valueRenderer = (selectedOption: string[] | string) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {typeof selectedOption === 'object'
        ? selectedOption.map((select) => <Chip key={select} label={options[select].label} />)
        : options[selectedOption]?.label}
    </Box>
  );

  return (
    <Controller
      name={key}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          select
          id={key}
          fullWidth
          required={required}
          label={
            <InputLabel error={!!error} required={required}>
              {label}
            </InputLabel>
          }
          helperText={getHelperText(helper, error?.message)}
          InputLabelProps={huiInputLabelProps}
          error={!!error}
          SelectProps={{
            sx: { textAlign: 'left' },
            multiple: isMultiple,
            renderValue: (keys) => valueRenderer(keys as string[]),
            MenuProps: { PaperProps: { sx: { maxHeight: '45vh' } } },
          }}
          {...other}
          {...field}
        >
          {optionKeys.map((key) => {
            const { label, description } = options[key];

            return (
              <MenuItemStyled key={key} value={key}>
                {isMultiple && Array.isArray(field.value) ? (
                  <>
                    <Checkbox checked={field.value.indexOf(key) > -1} />
                    {label}
                  </>
                ) : (
                  <Stack spacing={0}>
                    <Typography>{label}</Typography>
                    {description && <Typography variant="caption">{description}</Typography>}
                  </Stack>
                )}
              </MenuItemStyled>
            );
          })}
        </TextField>
      )}
    />
  );
}
