import { Typography, Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '60vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  width: 240,
  margin: '16px auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

interface Props {
  title?: string;
}

export default function ComingSoon({ title }: Props) {
  return (
    <ContentStyle sx={{ textAlign: 'center' }}>
      <Typography variant="h3" paragraph>
        Coming Soon...
      </Typography>

      <BoxStyle>
        {/* <img src="https://i.giphy.com/media/E6jscXfv3AkWQ/giphy.webp" alt="typing cat" /> */}
      </BoxStyle>
    </ContentStyle>
  );
}
