import { ReactNode } from 'react';

import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';

import { useTheme, alpha, IconButton, Theme } from '@mui/material';
import { GlobalStyles } from '@mui/styled-engine';
import { Close } from 'mdi-material-ui';

const snackbarStyles = (theme: Theme) => {
  const isLight = theme.palette.mode === 'light';
  return {
    '#__next': {
      '.SnackbarContainer-top': {
        top: theme.spacing(1.5),
        right: theme.spacing(1.5),
        '.MuiCollapse-wrapper': {
          padding: theme.spacing(0.5),
        },
      },
      '.SnackbarContainer-root': {
        [theme.breakpoints.down('sm')]: { left: 0, transform: 'translateX(0)' },
      },
      '.SnackbarContent-root': {
        // width: '100vw',
        // margin: 0,
        borderRadius: theme.shape.borderRadius,
        padding: '11px 16px',
        boxShadow: 'none',
        color: isLight ? theme.palette.common.white : theme.palette.grey[800],
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)',
        // [theme.breakpoints.up('md')]: {
        //   minWidth: '70vw',
        // },
        '&.SnackbarItem-variantSuccess': {
          backgroundColor: alpha(theme.palette.primary.main, 0.7),
        },
        '&.SnackbarItem-variantError': {
          color: theme.palette.error.darker,
          backgroundColor: alpha(theme.palette.error.main, 0.7),
        },
        '&.SnackbarItem-variantWarning': {
          color: theme.palette.warning.darker,
          backgroundColor: alpha(theme.palette.warning.main, 0.6),
        },
        '&.SnackbarItem-variantInfo': {
          backgroundColor: alpha(theme.palette.info.main, 0.7),
        },
      },
      '.SnackbarItem-message': {
        fontWeight: theme.typography.fontWeightMedium,
        margin: 'auto',
        whiteSpace: 'pre-line',
      },
      '.SnackbarItem-action': {
        marginRight: 0,
        color: theme.palette.action.active,

        '& svg': {
          color: 'white',
          width: 20,
          height: 20,
        },
      },
    },
  };
};

type SnackbarCloseProps = { snackbarKey: SnackbarKey };
function SnackbarClose({ snackbarKey }: SnackbarCloseProps) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
}

type Props = {
  children: ReactNode;
};

const SnackbarWrapper = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <>
      {/* @ts-ignore */}
      <GlobalStyles styles={snackbarStyles(theme)} />
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        action={(snackbarKey) => <SnackbarClose snackbarKey={snackbarKey} />}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

export default SnackbarWrapper;
